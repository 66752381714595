<template>
  <div id="scene-container" ref="sceneContainer">
    <div id="ui-container" ref="uiContainer">
      <Nav v-if="isReady" v-on:audioPressed="toggleAudio"/>
      <Welcome v-if="needsWelcome" v-on:triggered="startedScene"/>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import {
  Mesh,
  BoxGeometry, MeshBasicMaterial,
  WebGLRenderer,
  sRGBEncoding, Clock,
  Audio,
  AudioAnalyser,
  AudioListener,
  AudioLoader,
  PositionalAudio,
  IcosahedronBufferGeometry,
} from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {Water} from 'three/examples/jsm/objects/Water.js';
import {Sky} from 'three/examples/jsm/objects/Sky.js';

import SimplexNoise from 'simplex-noise'


import Nav from "../components/Nav"
import Welcome from "../components/Welcome"

export default {
  components: {
    Nav,
    Welcome
  },
  data() {
    return {
      //cubeModel: null,
      isVisible: true,
      sphereMesh: null,
      sphereGeometry: null,
      simplex: null,
      clock: null,
      analyser: null,
      flyover: null,
      ambience: null,
      isReady: false,

      audioContexts: [],
      biquadFilters: [],

    }
  },
  methods: {
    async init() {
      // set container
      this.container = this.$refs.sceneContainer

      // add camera
      this.camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 100);
      this.camera.position.set(10, 2, 10);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      // create renderer
      this.renderer = this.createRenderer()
      this.container.appendChild(this.renderer.domElement)

      // create scene
      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color('#EEEEEE')
      this.scene.fog = new THREE.FogExp2(0xEEEEEE, 0.015);


      this.clock = new Clock()

      this.sphereUnder = false



      // add lights
      //const hemiLight = new THREE.HemisphereLight(0xffffff, 0x080808, 1.5);
      //hemiLight.position.set(-1.25, 1, 1.25);

      //const mainLight = new THREE.DirectionalLight(0xffffff, 4.0)
      //mainLight.position.set(-10, 10, 10)
      //this.scene.add(hemiLight, mainLight)

      // cube geometry
      //this.cubeModel = this.createCube(2,2,2)
      //this.cubeModel.position.z = 0
      //this.scene.add(this.cubeModel);

      // Sun

      this.sun = new THREE.Vector3();

      // Water
      const waterGeometry = new THREE.PlaneGeometry(150, 150);

      this.water = new Water(
          waterGeometry,
          {
            textureWidth: 256,
            textureHeight: 256,
            waterNormals: new THREE.TextureLoader().load('textures/waternormals.jpg', function (texture) {

              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

            }),
            sunDirection: new THREE.Vector3(),
            sunColor: 0xb8b8b8,
            waterColor: 0x000026,
            distortionScale: 3.7,
            fog: this.scene.fog !== undefined
          }
      );

      this.water.rotation.x = -Math.PI / 2;

      this.scene.add(this.water);


      // Skybox
      const sky = new Sky();
      sky.scale.setScalar(10000);
      this.scene.add(sky);

      const skyUniforms = sky.material.uniforms;

      skyUniforms['turbidity'].value = 15;
      skyUniforms['rayleigh'].value = 3;
      skyUniforms['mieCoefficient'].value = 0.1;
      skyUniforms['mieDirectionalG'].value = 0;

      const parameters = {
        elevation: 0,
        azimuth: 180
      };

      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);

      const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
      const theta = THREE.MathUtils.degToRad(parameters.azimuth);

      this.sun.setFromSphericalCoords(1, phi, theta);

      sky.material.uniforms['sunPosition'].value.copy(this.sun);
      this.water.material.uniforms['sunDirection'].value.copy(this.sun).normalize();

      this.scene.environment = pmremGenerator.fromScene(sky).texture;


      this.loader = new AudioLoader()
      this.loader.setPath('audio/')
      this.listener = new AudioListener()
      this.ambience = new Audio(this.listener)
      this.flyover = new PositionalAudio(this.listener)
      this.camera.add(this.listener)


      if (this.needsWelcome == false) {
        await this.startedScene()

      }

    },

    async startedScene() {

      this.controls = new OrbitControls(this.camera, this.container)
      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.04;
      this.controls.screenSpacePanning = false;
      this.controls.enableZoom = false;
      this.controls.minDistance = 22;
      this.controls.maxDistance = 50;
      this.controls.minPolarAngle = Math.PI / 15;
      this.controls.maxPolarAngle = Math.PI / 2.25;

      await this.setupAudio()
      if (this.$store.getters.playingAudio == 'true') {
        this.ambience.play()
        this.flyover.play()
      }
      this.prepareFilters(22000, this.ambience)
      this.prepareFilters(22000, this.flyover)



    },

    prepareFilters(targetValue, audioTrack){
      this.audioContexts.push(audioTrack.context)
      this.biquadFilters.push(this.audioContexts[this.audioContexts.length-1].createBiquadFilter())
      this.biquadFilters[this.biquadFilters.length-1].type = "lowpass"
      audioTrack.setFilter(this.biquadFilters[this.biquadFilters.length-1])
      this.biquadFilters[this.biquadFilters.length-1].frequency.setValueAtTime(targetValue, this.audioContexts[this.audioContexts.length-1].currentTime);
    },

    async setupAudio() {
      this.isVisible = false
      await this.createSphere()
      await this.playAmbience()

      // TODO: better place to summarise the scene has been set up
      this.isReady = true
    },

    avg(arr) {
      var total = arr.reduce(function (sum, b) {
        return sum + b
      })
      return (total / arr.length)
    }
    ,
    createRenderer() {
      const renderer = new WebGLRenderer({antialias: true})
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.gammaFactor = 2.2
      renderer.outputEncoding = sRGBEncoding
      renderer.physicallyCorrectLights = true
      return renderer
    },


    async createSphere() {
      this.simplex = new SimplexNoise(4)
      this.sphereGeometry = new IcosahedronBufferGeometry(1, 4)
      const material = new MeshBasicMaterial({color: 0xaa0000, wireframe: true})
      this.sphereMesh = new Mesh(this.sphereGeometry, material)
      this.sphereMesh.position.x = -10
      this.sphereMesh.position.z = 10
      this.scene.add(this.sphereMesh)
      await this.playPositional()
    },

    updateSphere(geometry, baseFr, trebleFr, time) {
      const amp = 0.5
      const rf = 0.01
      const radius = geometry.parameters.radius
      const position = geometry.getAttribute('position') // can be called on geometry not mesh
      for (let i = 0; i < position.array.length; i += 3) {
        const vertex = new THREE.Vector3(position.array[i], position.array[i + 1], position.array[i + 2])
        vertex.normalize()
        const distance = radius + baseFr * amp * 0.5 + this.simplex.noise3D(vertex.x + time * rf, vertex.y + time * rf, vertex.z + time * rf) * amp * trebleFr
        //const distance = radius + baseFr * amp * 0.5 + (vertex.x + time * rf, vertex.y + time * rf, vertex.z + time * rf) * amp * trebleFr

        vertex.multiplyScalar(distance)
        position.array[i] = vertex.x
        position.array[i + 1] = vertex.y
        position.array[i + 2] = vertex.z
      }
      position.needsUpdate = true

    },

    playAmbience() {
      return new Promise((resolve) => {
        this.loader.load('Forest_atmo.mp3', buffer => {
              this.ambience.setBuffer(buffer)
              this.ambience.setLoop(true)
              this.ambience.setVolume(1.0)
              //this.ambience.play()
              //this.analyser = new AudioAnalyser(this.ambience, 128)
              resolve(buffer)
            },
            undefined,
            undefined
        )
      })

    },
    playPositional() {
      return new Promise((resolve) => {
        this.loader.load('Torus_object.mp3', buffer => {
              this.flyover.setBuffer(buffer)
              this.flyover.setLoop(true)
              //this.flyover.setVolume(1.0)
              this.flyover.setRefDistance(5.0)
              //this.flyover.play()
              this.analyser = new AudioAnalyser(this.flyover, 128)
              this.sphereMesh.add(this.flyover)
              resolve(buffer)
            },
            undefined,
            undefined
        )
      })
    },

    async toggleAudio(toEnable) {


      if (!toEnable) {
        this.ambience.setVolume(0.0)
        this.flyover.setVolume(0.0)
        this.ambience.stop()
        this.flyover.stop()

      } else {
        this.ambience.setVolume(1.0)
        this.flyover.setVolume(1.0)
        this.ambience.play()
        this.flyover.play()

      }
      setTimeout(() => {

      }, 250)


    },

    establishListeners() {
      window.addEventListener('resize', this.onWindowResize.bind(this), false)

    },

    onWindowResize() {
      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);

    },

    createCube(width, height, depth) {
      const geometry = new BoxGeometry(width, height, depth)

      const material = new MeshBasicMaterial({color: 0x76d32e});
      return new Mesh(geometry, material)
    },

    /*
        createSphere () {
          sphereGeometry = new IcosahedronBufferGeometry(1, 4)
          const material = new MeshBasicMaterial({ color: 0xaa0000, wireframe: true })
          sphereMesh = new Mesh(sphereGeometry, material)
          scene.add(sphereMesh)
        }
    */



    render() {
      this.renderer.render(this.scene, this.camera)
      // this.stats.update()
      //rotate model
      this.water.material.uniforms['time'].value += 1.0 / 120.0;
    },

    animate() {
      this.renderer.setAnimationLoop(() => {
        this.render()

        if (!this.isVisible) {

          let delta = this.clock.getDelta()
          this.controls.update(delta)


          let time = performance.now() * 0.0005

          this.sphereMesh.position.x = Math.sin(time * 0.6) * 9
          this.sphereMesh.position.y = Math.sin(time * 0.7) * 9 + 6
          this.sphereMesh.position.z = Math.sin(time * 0.8) * 9

          if (this.sphereMesh.position.y < 0 && !this.sphereUnder){
            this.sphereUnder = true
            for (let i = 0; i < this.biquadFilters.length; i++){
              this.biquadFilters[i].frequency.setValueAtTime(800, this.audioContexts[i].currentTime);
            }

          }
          else if (this.sphereMesh.position.y > 0 && this.sphereUnder){
            this.sphereUnder = false
            for (let i = 0; i < this.biquadFilters.length; i++){
              this.biquadFilters[i].frequency.setValueAtTime(22000, this.audioContexts[i].currentTime);
            }

          }


          this.sphereMesh.rotation.x = time
          this.sphereMesh.rotation.z = time
          if (this.analyser) {
            const time2 = this.clock.getElapsedTime()

            const divisor = 32
            const data = this.analyser.getFrequencyData()
            // const avgFr = this._analyser.getAverageFrequency()

            const lowerHalfArray = data.slice(0, data.length / 2 - 1)
            const upperHalfArray = data.slice(data.length / 2 - 1, data.length - 1)
            const lowerAvgFr = this.avg(lowerHalfArray) / divisor
            const upperAvgFr = this.avg(upperHalfArray) / divisor
            // this.updateBall(this._ball, lowerHalfArray, upperHalfArray, time) // it was supposed to be this but throws Computed radius is NaN. The "position" attribute is likely to have NaN
            this.updateSphere(this.sphereGeometry, lowerAvgFr, upperAvgFr, time2)
          }
        }

      })
      this.establishListeners()
    },
  },
  mounted() {
    this.init().then(() => this.animate())

  },
  beforeDestroy() {
    this.ambience.pause()
    this.flyover.pause()

  },


  computed: {
    needsWelcome() {
      return !this.$store.getters.enteredWeb;
    },

  }
}
</script>

<style scoped>
#scene-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 96;
}

#ui-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
</style>
